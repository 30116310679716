import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { clsx } from 'clsx';
import { isBefore } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { makeNavPath } from '@creator-portal/common/util/makeNavPath';
import { canAccess } from '@creator-portal/common/auth';
import { PERMISSION } from '@creator-portal/common/permissions/constants';
import { PERSONAL_TEAM_ID } from '@creator-portal/common/publishing/constants';
import { calculateVisiblePosts } from '@/services/cms/calculateVisiblePosts';
import { getCreatorNewsArticles } from '@/services/cms/cms-services';
import { getAlerts } from '@/services/profile/profile-service';
import CarouselBlog from '@/components/Blog/CarouselBlog/CarouselBlog.component';
import InfoBox from '@/components/common/alerts/info-box';
import CreatorAlertComponent from '@/components/common/creator.alert';
import { ErrorBoundary } from '@/components/common/error-boundary';
import MainLayout from '@/components/common/main.layout';
import StandaloneLink from '@/components/common/standalone-link';
import Button from '@/components/ui/Button';
import PicksBanner from '@/components/welcome/picks-banner';
import { useAuthSession } from '@/hooks/useAuthSession';
import { useCreatorProgramStatus } from '@/hooks/useCreatorProgramStatus';
import useLocalStorageKey from '@/hooks/useLocalStorageKey';
import { getServerSideTranslations } from '@/util/getServerSideTranslations';
import { getLocale } from '@/util/locale';
import { requireAuth } from '@/util/requireAuth';
import * as Xhr from '@/util/xhr';
import useStyles from './index.styles';
import type { ShortNews } from '@/services/cms/cms-services';
import type { CreatorAlert } from '@creator-portal/common/types';
import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { JSX } from 'react';
export interface BlogInfo {
  blogs: ShortNews[];
  blogTotal: number;
  locale: string;
}
export async function getServerSideProps(ctx: GetServerSidePropsContext): Promise<GetServerSidePropsResult<BlogInfo>> {
  if (!ctx.query.team) {
    return {
      redirect: {
        destination: makeNavPath('/welcome', ctx.resolvedUrl, {
          searchParams: {
            team: PERSONAL_TEAM_ID
          }
        }),
        permanent: true
      }
    };
  }
  const locale = getLocale(ctx);
  const translations = await getServerSideTranslations(locale);
  const blogsResponse = await getCreatorNewsArticles(locale);
  const publicPepUrl = '/island-creator/overview';

  // get alerts has moved to client fetch to avoid any potential blocking due to service connection

  const state: GetServerSidePropsResult<BlogInfo & {
    publicPepUrl: string;
  }> = {
    props: {
      locale,
      publicPepUrl,
      blogs: blogsResponse.shortNews,
      blogTotal: blogsResponse.total,
      ...translations
    }
  };
  if (ctx.req.cookies.as_user) {
    const xhr = Xhr.getInstance(ctx);
    return await requireAuth(xhr, () => state);
  }
  return state;
}
export default function StudioWelcomeIndexPage({
  blogs,
  blogTotal,
  locale,
  publicPepUrl
}: BlogInfo & {
  publicPepUrl: string;
}): JSX.Element | null {
  const [alerts, setAlerts] = useState<CreatorAlert[]>([]);
  const classes = useStyles();
  const {
    push,
    query
  } = useRouter();
  const {
    t
  } = useTranslation();
  const user = useAuthSession();
  const creatorProgramStatus = useCreatorProgramStatus();
  const banned = creatorProgramStatus?.banned;
  const visiblePosts = calculateVisiblePosts();
  const isIcpFeatureAvailable = canAccess(user, PERMISSION.ICP_FEATURE);
  const showAnalyticsLaunchAlertFlag = canAccess(user, PERMISSION.SHOW_ANALYTICS_LAUNCH_ALERT) && !banned;
  const showEpicsPicks = canAccess(user, PERMISSION.SHOW_EPICS_PICKS) && !banned;
  const showEstimatesLaunchAlertFlag = canAccess(user, PERMISSION.SHOW_ESTIMATES_LAUNCH_ALERT) && !banned;
  const showSh0317Banner = canAccess(user, PERMISSION.SH_03_17_BANNER) && !banned;
  const showSh0319Banner = canAccess(user, PERMISSION.SH_03_19_BANNER) && !banned;
  const loggedIn = !!user;
  useEffect(() => {
    if (loggedIn) {
      const setupAlerts = async () => {
        const creatorAlerts = await getAlerts(locale);
        setAlerts([...(creatorAlerts?.alerts || []), ...(creatorAlerts?.announcements || [])]);
      };
      setupAlerts().catch((e: unknown) => console.log(e));
    }
  }, [loggedIn, locale]);
  const handleClick = () => {
    if (!loggedIn) return void push('/auth/login');else return void push(isIcpFeatureAvailable ? '/enroll' : publicPepUrl);
  };
  let showButton = true;
  let buttonLabel = '';
  let subTitleText = '';
  if (creatorProgramStatus?.enrolled || creatorProgramStatus?.banned) showButton = false;
  if (creatorProgramStatus?.needsToEnroll) showButton = true;
  if (creatorProgramStatus?.resumeEnroll) showButton = false;
  if (loggedIn) {
    subTitleText = creatorProgramStatus?.needsToEnroll ? t('welcome.subtitle.enroll') : t('welcome.subtitle.is-enrolled');
    buttonLabel = t('button.enroll-now');
  } else {
    showButton = true;
    buttonLabel = t('navbar.login');
    subTitleText = t('welcome.subtitle.sign-in');
  }
  const isShownContinueEnrollmentAlert = isIcpFeatureAvailable && loggedIn && creatorProgramStatus?.resumeEnroll;
  const {
    value: isShownAnalyticsLaunchAlert,
    handleSetKey: handleSetAnalyticsLaunchAlertKey
  } = useLocalStorageKey('alertAnalyticsPlays');
  const alertAnalyticsLaunch = {
    type: 'info',
    severity: 'info',
    messages: [t('welcome.alert.analytics-launch')],
    closeable: true,
    popup: false
  };
  const isShownIslandCodeInfo = isBefore(new Date(), new Date('2024-12-25T00:00:00Z'));
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>{t('welcome.seo.title')}</title>
        <meta name="description" content={t('welcome.seo.description')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="twitter:title" content={t('welcome.seo.twitter.title')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="twitter:description" content={t('welcome.seo.description')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      </Head>
      <MainLayout data-sentry-element="MainLayout" data-sentry-source-file="index.tsx">
        {loggedIn && showAnalyticsLaunchAlertFlag && !isShownAnalyticsLaunchAlert ? <Stack spacing={3} className={classes.alertsArea}>
            <CreatorAlertComponent alert={alertAnalyticsLaunch} onClick={() => handleSetAnalyticsLaunchAlertKey('true')} />
          </Stack> : null}

        {alerts.length > 0 ? <Stack spacing={3} className={classes.alertsArea}>
            {alerts.map(alert => <CreatorAlertComponent key={alert.key || alert.type} alert={alert} />)}
          </Stack> : null}
        {isShownContinueEnrollmentAlert ? <Stack spacing={3} className={classes.alertsArea}>
            <InfoBox severity="info" className={classes.continueEnrollAlert} onSubmit={handleClick} submitButtonText={t('button.continue-enrollment')}>
              {t('welcome.alert.continue-enrollment')}
            </InfoBox>
          </Stack> : null}
        {loggedIn && isShownIslandCodeInfo ? <Stack spacing={3} className={classes.alertsArea}>
            <InfoBox severity="info" className={classes.continueEnrollAlert}>
              <span className={classes.temporaryAlertTitle}>{t('welcome.banner.generate-new-island-code.title')}</span>
              <span className={classes.temporaryAlertSubtitle}>{t('welcome.banner.generate-new-island-code.description')}</span>
            </InfoBox>
          </Stack> : null}
        {loggedIn && showEstimatesLaunchAlertFlag ? <Stack spacing={3} className={classes.alertsArea}>
            <InfoBox severity="info" className={classes.continueEnrollAlert}>
              {t('welcome.alert.estimates-launch')}
            </InfoBox>
          </Stack> : null}
        {loggedIn && showSh0317Banner ? <Stack spacing={3} className={classes.alertsArea}>
            <InfoBox severity="info" className={classes.continueEnrollAlert}>
              <Trans i18nKey="alert.sh-0317-banner.description" components={{
            a: <a href={`https://dev.epicgames.com/documentation/${locale}/fortnite-creative/project-analytics-for-fortnite-games`} target="_blank" rel="noopener noreferrer" />
          }} />
            </InfoBox>
          </Stack> : null}

        {loggedIn && showSh0319Banner ? <Stack spacing={3} className={classes.alertsArea}>
            <InfoBox severity="info" className={classes.continueEnrollAlert}>
              <span className={classes.temporaryAlertTitle}>{t('alert.sh-0319-banner.title')}</span>
              <span className={classes.temporaryAlertSubtitle}>{t('alert.sh-0319-banner.description')}</span>
            </InfoBox>
          </Stack> : null}
        <div className={clsx(classes.welcomeBlock, classes.contentTopPadding)} data-testd="welcome">
          <div className={classes.top}>
            <Typography className={classes.titleWrapper} variant="h1" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              <Trans i18nKey="welcome.title" components={{
              span: <div className={classes.welcomeTitle} />,
              strong: <div className={classes.title} />
            }} data-sentry-element="Trans" data-sentry-source-file="index.tsx" />
            </Typography>
            <Typography className={classes.subTitle} variant="h2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {subTitleText}
            </Typography>
            {showButton ? <Button data-test="welcome-button" onClick={handleClick} className={classes.btn}>
                {buttonLabel}
              </Button> : null}
          </div>
          <div className={clsx(classes.news, classes.marginForArabian)}>
            <Typography className={classes.newsTitle} variant="h3" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              {t('welcome.latest-news')}
            </Typography>
            <Typography className={classes.newsExpand} variant="h4" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
              <StandaloneLink href={`/news${query?.lang ? `?lang=${query.lang as string}` : ''}`} data-sentry-element="StandaloneLink" data-sentry-source-file="index.tsx">{t('link.view-all')}</StandaloneLink>
            </Typography>
          </div>
          <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="index.tsx">
            <CarouselBlog locale={locale} visiblePosts={visiblePosts} blogs={blogs} blogTotal={blogTotal} data-sentry-element="CarouselBlog" data-sentry-source-file="index.tsx" />
          </ErrorBoundary>

          {showEpicsPicks ? <PicksBanner /> : null}
        </div>
      </MainLayout>
    </>;
}