import { ReactNode, useMemo } from 'react';
import { CloseIcon } from '@epic-ui/icons';
import { Alert, IconButton } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import Typography from '@mui/material/Typography';
import DOMPurify from 'isomorphic-dompurify';
import { CreatorAlert } from '@creator-portal/common/types';
import Button from '@/components/ui/Button';
import useStyles from './creator.alert.styles';
import type { JSX } from 'react';
type ExtendedCreatorAlert = Omit<CreatorAlert, 'messages'> & {
  messages: (string | ReactNode)[];
};
interface CreatorAlertComponentProps {
  alert: ExtendedCreatorAlert;
  onClick?: () => void;
}
const CreatorAlertComponent: React.FunctionComponent<CreatorAlertComponentProps> = ({
  alert,
  onClick
}): JSX.Element | null => {
  const classes = useStyles();
  const messages = alert.messages;
  const sanitizedMessages = useMemo(() => messages.map(m => m ? typeof m === 'string' ? DOMPurify.sanitize(m) : m : ''), [messages]);
  return <>
      <Alert variant="filled" classes={classes} className={classes.alert} severity={alert.severity as AlertColor || 'info'} action={alert.cta && alert.cta.message ? <Button size="small" className={classes.cta} href={alert.cta.link}>
              {alert.cta.message}
            </Button> : alert.closeable ? <IconButton size="small" onClick={onClick} className={classes.closeButton}>
              <CloseIcon fontSize="small" />
            </IconButton> : undefined} data-sentry-element="Alert" data-sentry-source-file="creator.alert.tsx">
        <div>
          {sanitizedMessages.map((m, i) => typeof m === 'string' ? <Typography variant="body1" key={`alert-${alert.type}-${i}`} sx={{
          mb: 1,
          mt: 1
        }} dangerouslySetInnerHTML={{
          __html: m
        }} /> : m)}
        </div>
      </Alert>
    </>;
};
export default CreatorAlertComponent;