import { WarningIcon } from '@epic-ui/icons';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import useStyles from './standalone-link.styles';
interface StandaloneLinkProps {
  href?: string;
  download?: boolean;
  target?: string;
  rel?: string;
  additionalClasses?: string | string[];
  onClick?: (e: React.SyntheticEvent) => void;
  testId?: string;
  downloadName?: string;
}
const StandaloneLink: React.FC<React.PropsWithChildren<StandaloneLinkProps>> = ({
  href,
  download,
  onClick,
  target,
  rel,
  children,
  additionalClasses,
  testId,
  downloadName
}) => {
  const classes = useStyles();
  const {
    t
  } = useTranslation();
  const handleLinkClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!href) {
      e.preventDefault();
      onClick?.(e);
      return;
    }
    if (download) {
      e.preventDefault();
      onClick?.(e);
      try {
        const response = await fetch(href);
        if (!response.ok) {
          throw new Error(`Error downloading file: ${response.statusText}`);
        }
        const blob = await response.blob();
        const tempUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = tempUrl;
        link.setAttribute('download', downloadName || 'default-name.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(tempUrl);
        toast.success(t('toast.download.success'));
      } catch (error) {
        toast.error(t('toast.download.error'), {
          icon: <WarningIcon />
        });
      }
    } else {
      onClick?.(e);
    }
  };
  const linkHref = download ? '#' : href;
  const linkTarget = download ? undefined : target;
  return <a data-testid={testId} className={clsx(classes.link, additionalClasses)} rel={rel} href={linkHref} onClick={handleLinkClick} target={linkTarget} data-sentry-component="StandaloneLink" data-sentry-source-file="standalone-link.tsx">
      {children}
    </a>;
};
export default StandaloneLink;