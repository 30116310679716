import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { WorkSansFont } from '@/components/common/main.layout.styles';

import getPaletteModeStyles from '@/util/getPaletteModeStyles';

import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => {
  const isRTL = theme.direction === 'rtl';

  return createStyles({
    swiper: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),

      paddingBottom: theme.spacing(6),
      position: 'unset',
    },
    swiperWrapper: {
      position: 'relative',

      '& .swiper-wrapper': {
        width: 'calc(100% + 50px)',
      },
    },
    swiperCardWrapper: {
      height: '100%',
    },
    newsItem: {
      background: theme.palette.shade7,
      borderRadius: '8px',
      padding: theme.spacing(3.2),
      minHeight: '400px',
      transition: 'all 0.2s ease-in-out',
      boxShadow: `0px 12px 24px ${getPaletteModeStyles(theme.palette.mode)(alpha(theme.palette.shade0, 0.05), 'none')}`,
      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0px 12px 24px ${getPaletteModeStyles(theme.palette.mode)(alpha(theme.palette.shade0, 0.1), 'none')}`,
        backgroundColor: getPaletteModeStyles(theme.palette.mode)(theme.palette.shade7, alpha(theme.palette.shade7, 0.6)),
      },
    },
    newsItemTitle: {
      fontFamily: WorkSansFont,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(23),
      letterSpacing: '-0.5px',
      textTransform: 'capitalize',
      color: theme.palette.shade1,
      marginTop: theme.spacing(4),
      textAlign: isRTL ? 'right' : 'left',
    },
    newsItemSubTitle: {
      fontFamily: 'Inter',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      letterSpacing: '-0.5px',
      textTransform: 'uppercase',
      color: theme.palette.shade2,
      marginTop: theme.spacing(2.4),
      textAlign: isRTL ? 'right' : 'left',
    },
    newsImg: {
      borderRadius: '8px',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '250px',
    },
  });
});

export default useStyles;
