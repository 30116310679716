import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.blue,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
      },
      '&:active': {
        textDecoration: 'none',
      },
    },
  }),
);

export default useStyles;
