import Box from '@mui/material/Box';
import { clsx } from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import { getEpicsPicksApplyNowUrl, getEpicsPicksUrl } from '@/config/common/functions';
import Button from '@/components/ui/Button';
import { useAuthSession } from '@/hooks/useAuthSession';
import { trackClickInteraction } from '@/util/tracking';
import useStyles from './picks-banner.styles';
import type { JSX } from 'react';
export default function PicksBanner(): JSX.Element | null {
  const classes = useStyles();
  const {
    t,
    i18n
  } = useTranslation();
  const user = useAuthSession();
  const handleApplyButtonClick = () => {
    trackClickInteraction({
      eventCategory: 'EpicsPicks',
      eventAction: 'ApplyNow',
      eventLabel: 'WelcomePage',
      portalAccountId: user?.accountId
    });
  };
  return <Box className={clsx(classes.banner)} data-sentry-element="Box" data-sentry-component="PicksBanner" data-sentry-source-file="picks-banner.tsx">
      <div className={classes.imageWrapper}>
        <div className={classes.image} />
      </div>
      <div>
        <h3 className={classes.header}>{t('welcome.banner.picks.header')}</h3>
        <div className={classes.content}>
          <Trans i18nKey="welcome.banner.picks.content" components={{
          a: <a href={getEpicsPicksUrl(i18n.language)} target="_blank" rel="noopener noreferrer" />
        }} data-sentry-element="Trans" data-sentry-source-file="picks-banner.tsx" />
        </div>
      </div>
      <Button className={classes.btn} variant="outlined" href={getEpicsPicksApplyNowUrl(i18n.language)} target="_blank" rel="noopener noreferrer" onClick={handleApplyButtonClick} data-sentry-element="Button" data-sentry-source-file="picks-banner.tsx">
        {t('button.apply-now')}
      </Button>
    </Box>;
}